import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout.jsx";
import SEO from "../components/seo.jsx";

import styles from "./../styles/modules/work.module.scss";

const WorkPage = ({ data }) => {
  return (
    <Layout header="simple" headerTitle="Some of my finest work.">
      <SEO
        title="Work"
        keywords={[
          `web`,
          `developer`,
          "frontend",
          "front end",
          "user experience",
          "ux",
          "designer",
          "javascript developer",
          "application",
          "react"
        ]}
      />
      <main className={styles.main}>
        <GenerateProjectList data={data} />
      </main>
    </Layout>
  );
};

const GenerateProjectList = ({ data }) => {
  let leftSide = [];
  let rightSide = [];
  data.projects.edges.map((project, index) => {
    if ((index + 1) % 2 === 0) {
      rightSide.push(project.node.frontmatter);
    } else {
      leftSide.push(project.node.frontmatter);
    }
    return project.node.frontmatter;
  });

  return (
    <>
      <div className={styles.projectList}>
        {leftSide.map((project, index) => {
          if ((index + 1) % 2 === 0) {
            return (
              <ProjectLink project={project} isSmall={false} key={index} />
            );
          } else {
            return <ProjectLink project={project} isSmall={true} key={index} />;
          }
        })}
      </div>
      <div className={styles.projectList}>
        {rightSide.map((project, index) => {
          if ((index + 1) % 2 === 0) {
            return <ProjectLink project={project} isSmall={true} key={index} />;
          } else {
            return (
              <ProjectLink project={project} isSmall={false} key={index} />
            );
          }
        })}
      </div>
    </>
  );
};

const ProjectLink = ({ project, isSmall }) => {
  return (
    <Link
      to={`/work/${project.slug}`}
      title={project.title}
      className={styles.projectItem}
    >
      <div className={`${styles.thumb} ${isSmall ? styles.small : ""}`}>
        <img
          src={project.largeThumb.childImageSharp.fluid.src}
          alt={project.title}
        />
      </div>
      <h2>{project.title}</h2>
      <p>{project.shortDescription}</p>
    </Link>
  );
};

export const staticQuery = graphql`
  {
    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(projects)/.*.md$/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            shortDescription
            largeThumb {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default WorkPage;
